.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  display: flex;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  align-items: center;

}

.databox {
  background-color: #1d366e;
  display: flex;
  flex-direction: row;
  padding: 35px;
}

.formbox {
  display: flex;
  flex-direction: row;
}


.ProfitData {
  font-size: calc(2px + 2vmin);
  color: white;
  width: 35%;
}

.MinerData {
  font-size: calc(2px + 2vmin);
  color: white;
  padding-right: 20px;
}

.textbox {
  padding-right: 10px;
}
